.terminal {
    background-color: black;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    font-size: 18px;
    padding: 20px;
    height: 100vh;
    overflow-y: auto;
  }

  .terminal.rebooting {
    background-color: red !important;
    color: white !important;
  }
  
  .terminal.rebooting .terminal-line {
    font-family: monospace;
    white-space: pre;
  }
  
  .terminal-line {
    text-align: left;
    white-space: pre-wrap;
  }
  
  .input-line {
    display: flex;
    align-items: center;
    text-align: left;
  }
  
  .prompt {
    margin-right: 5px;
  }
  
  .input-field {
    background: transparent;
    border: none;
    outline: none;
    color: #00ff00;
    font-family: inherit;
    font-size: inherit;
  }
  
  .cursor {
    display: none;
  }
  
  @keyframes blink {
    0%, 50% {
      opacity: 1;
    }
    50.01%, 100% {
      opacity: 0;
    }
  }