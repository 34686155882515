/* App.css or a separate CSS file */

.suspect-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust based on your layout needs */
    justify-content: center;
  }
  
  .suspect-card {
    width: 200px; /* Adjust width as needed */
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .suspect-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .suspect-card h3 {
    margin: 0;
    padding: 10px;
    background-color: #f5f5f5; /* Light grey background */
    text-align: center;
  }
  
  .suspect-image-placeholder {
    height: 120px; /* Adjust height as needed */
    background-color: #eee; /* Placeholder color */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .suspect-description {
    padding: 10px;
    display: none; /* Hide description by default */
    background-color: #f9f9f9; /* Slightly different grey for contrast */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
  
  .suspect-card:hover .suspect-description {
    display: block; /* Show description on hover */
  }
  