body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000;
    color: #fff;
}

.header {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1em;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
    }
}

.left-column {
    flex: 2;
    padding-right: 1em;
}

.right-column {
    flex: 1;
    padding-left: 1em;
    background-color: #222;
    border-left: 1px solid #555;
    text-align: left;
    margin-top: 1em;
}

@media (min-width: 768px) {
    .right-column {
        margin-top: 0;
    }
}

.responsive-video {
    width: 100%;
    height: auto;
    max-height: 70vh;
    border: 1px solid #555;
}

.character-list {
    list-style-type: disc;
    padding-left: 20px;
    color: #ccc;
}

a {
    color: #4CAF50;
}

a:hover {
    color: #66bb6a;
}